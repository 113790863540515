exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artwork-detail-js": () => import("./../../../src/pages/artwork-detail.js" /* webpackChunkName: "component---src-pages-artwork-detail-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kunst-in-der-landschaft-js": () => import("./../../../src/pages/kunst-in-der-landschaft.js" /* webpackChunkName: "component---src-pages-kunst-in-der-landschaft-js" */),
  "component---src-pages-spielplaetze-js": () => import("./../../../src/pages/spielplaetze.js" /* webpackChunkName: "component---src-pages-spielplaetze-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

